import { CurrencyCodes, InvoiceStatusCodes, InvoiceTypeCodes, LanguageCodes } from '@common/classes/codes';
import { ControlSize } from '@common/classes/control-size';
import { ViewMode } from '@common/models/view-mode';
export const environment = {
  production: false,
  environment: 'base',
  serverUrl: `${window.location.protocol}//${window.location.hostname}:5001`,
  apiUrl: `${window.location.protocol}//${window.location.hostname}:5001/api`,
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  title: 'Billing Malta',
  version: '1.3.1',
  defaultLanguage: 'en',
  settings: {
    header: {
      logo: null,
      title: 'Billing',
      welcomeMessage: null,
      themeColor: 'dark',
      environmentColor: 'color: #fff'
    },
    nmswPermitViewUrl: 'http://localhost:4200/vessel-permit/view/',
    appControl: {
      format: 'n2',
      size: ControlSize.normal,
      multi: false,
      time: false,
      codelist: {
        take: 100
      },
      fileDownloadUrl: 'api:///query/DownloadAttachment',
      fileMaxSize: 5242880 * 4,
      fileAllowedExtensions: ['.jpg', '.jpeg', '.png', '.bmp', '.tif', '.doc', '.docx', '.xls', '.xlsx', '.txt', '.pdf', 'xslt', '.msg' // Outlook
      ],

      dropdown: {
        popupSettings: undefined
      }
    },
    grid: {
      pageable: {
        buttonCount: 4,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 50]
      },
      pageSize: 50,
      sortable: false,
      sort: [{
        field: 'id',
        dir: 'desc'
      }]
    },
    view: {
      defaultViewMode: ViewMode.view,
      save: {
        redirectToViewMode: ViewMode.view
      }
    },
    list: {
      persistFilter: true,
      rememberFilterState: true
    },
    rememberState: true,
    validation: {
      errors: {
        showTooltip: true,
        showFeedback: false
      }
    },
    currency: CurrencyCodes.Euro,
    newInvoice: () => ({
      statusId: InvoiceStatusCodes.Draft,
      typeId: InvoiceTypeCodes.Invoice,
      organizationUnitId: 'TM',
      currencyId: environment.settings.currency,
      languageId: LanguageCodes.English,
      createdDate: new Date(),
      taxRateDate: new Date(),
      exchangeRateDate: new Date(),
      electronicInvoice: true,
      serviceDate: new Date()
    }),
    roundingSettings: {
      price: 3,
      quantity: 3,
      amount: 2,
      taxAmount: 2,
      discountPercentage: 2
    }
  }
};